import classNames from 'classnames';
import React from 'react';

import { FocusContainer } from '@eika/focus-container';

import './CookieModal.scss';

type Props = {
    open?: boolean;
    onClose: () => void;
    title: string;
};

const CookieModal: React.FC<React.PropsWithChildren<Props>> = ({ open = true, onClose, title, children }) => {
    return (
        <>
            <div
                className={classNames('cookie-modal', { open })}
                role="dialog"
                aria-modal="true"
                aria-labelledby="cookie-modal-title"
            >
                <FocusContainer entry={open} onExit={() => null}>
                    <div className="cookie-modal__content">
                        <h2 id="cookie-modal-title">{title}</h2>
                        {children}
                    </div>
                </FocusContainer>
            </div>
            <div
                className={classNames('cookie-modal__backdrop', { open })}
                tabIndex={-1}
                onClick={onClose}
                onKeyUp={(event) => {
                    if (event.key === 'Escape' || event.key === 'Esc') {
                        onClose();
                    }
                }}
            />
        </>
    );
};

export default CookieModal;
