import classNames from 'classnames';
import React from 'react';

interface CookieFormSubmitProps {
    actions: { type: 'primary' | 'secondary' | 'tertiary'; onClick?: () => void; text: string }[];
    showSuccesss: boolean;
    successText: string;
}

const getButtonType = (type: 'primary' | 'secondary' | 'tertiary'): string => {
    switch (type) {
        case 'tertiary':
            return 'btn-flat';
        case 'secondary':
            return 'btn-default';
        case 'primary':
        default:
            return 'btn-primary';
    }
};

const CookieFormSubmit = ({ actions, showSuccesss, successText }: CookieFormSubmitProps): JSX.Element => (
    <div className="card-button-wrapper">
        {showSuccesss ? (
            <div className="alert-success card" role="alert" aria-live="assertive">
                <div className="card-body">{successText}</div>
            </div>
        ) : (
            <>
                {actions.map(({ type, onClick, text }) =>
                    onClick ? (
                        <button
                            type="button"
                            key={type}
                            className={classNames(`card-button-wrapper__button--${type}`, 'btn', getButtonType(type))}
                            data-testid={`${type}-cookie-action`}
                            onClick={onClick}
                        >
                            {text}
                        </button>
                    ) : null,
                )}
            </>
        )}
    </div>
);

export default CookieFormSubmit;
