import React, { FC } from 'react';

import useCookiePopup from '../../../hooks/useCookiePopup';
import useTranslation from '../../../hooks/useTranslation';
import CookieForm from '../../components/cookie-form/CookieForm';
import CookieModal from './CookieModal';

const Popup: FC = () => {
    const { modal, cookies, success } = useTranslation();

    const { closeModal, open, edit, setEdit, setOpen } = useCookiePopup(cookies, success.timeout);

    return (
        <CookieModal onClose={closeModal} open={open} title={modal.header}>
            <div className="card-ingress">
                <h3>{modal.subHeader}</h3>
                <ul>
                    {modal.description.map((desc) => (
                        <li key={desc}>{desc}</li>
                    ))}
                </ul>
            </div>

            <CookieForm
                enableEdit={edit}
                cookies={cookies}
                onCloseModal={() => setOpen(false)}
                onEdit={() => setEdit(true)}
                onCancel={() => setEdit(false)}
            />
        </CookieModal>
    );
};

export default Popup;
