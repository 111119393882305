import React, { FC } from 'react';

import { Cookie } from '../../../default-config';

interface CookieProps {
    id: string;
    cookie: Cookie;
    onChange: (cookieName: string) => void;
    checked: boolean;
}

const CookieSelect: FC<CookieProps> = ({ id, cookie, onChange, checked }) => {
    const isForced = cookie.isDisabled;
    const actuallyChecked = isForced || Boolean(checked);
    return (
        <div className="form-group">
            <input
                type="checkbox"
                data-testid="cookie-select"
                name={cookie.cookieName}
                id={`checkbox-cookie-${cookie.cookieName}-${id}`}
                checked={actuallyChecked}
                onChange={() => onChange(cookie.cookieName)}
                disabled={isForced}
            />
            <label htmlFor={`checkbox-cookie-${cookie.cookieName}-${id}`}>
                <span>{cookie.header}</span>
                <p>{cookie.description}</p>
            </label>
        </div>
    );
};
export default CookieSelect;
