import React, { FC } from 'react';
import { v4 as uuid } from 'uuid';

import useCookie from '../../../hooks/useCookie';
import useTranslation from '../../../hooks/useTranslation';
import { CookieType } from '../../../utils/cookie-utils';
import CookieFormSubmit from './CookieFormSubmit';
import CookieSelect from './CookieSelect';

interface Props {
    cookies: CookieType;
    onEdit?: () => void;
    onCancel?: () => void;
    onCloseModal?: () => void;
    enableEdit?: boolean;
}

const CookieForm: FC<Props> = ({ cookies, onEdit, onCancel, onCloseModal, enableEdit = false }) => {
    const { modal, form, success } = useTranslation();
    const { consents, onChange, onAcceptAll, onSave, saved } = useCookie(cookies, success.timeout, onCloseModal);

    return (
        <form className="cookie-consent-form">
            {enableEdit && (
                <>
                    <h3>{form.header}</h3>
                    <div className="checkbox">
                        {Object.values(cookies).map((cookie) => (
                            <CookieSelect
                                id={uuid()}
                                key={cookie.cookieName}
                                cookie={cookie}
                                onChange={onChange}
                                checked={consents[cookie.cookieName]}
                            />
                        ))}
                    </div>
                </>
            )}

            {enableEdit ? (
                <CookieFormSubmit
                    actions={[
                        { type: 'primary', onClick: onSave, text: form.saveChanges },
                        { type: 'secondary', onClick: onCancel, text: form.cancel },
                    ]}
                    showSuccesss={saved}
                    successText={success.firstTimeSaveDescription}
                />
            ) : (
                <CookieFormSubmit
                    actions={[
                        { type: 'primary', onClick: onAcceptAll, text: modal.acceptAll },
                        { type: 'secondary', onClick: onSave, text: modal.onlyNecessary },
                        { type: 'tertiary', onClick: onEdit, text: modal.edit },
                    ]}
                    showSuccesss={saved}
                    successText={success.description}
                />
            )}
        </form>
    );
};

export default CookieForm;
